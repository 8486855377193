body,
html,
#root {
  margin: 0;
  min-height: 100vh;
  font-family: "Open Sans", "sans-serif";
  background-color: #f9fc7e;
}

.App {
  margin: 0;
  min-height: 100vh;
}

.centered {
  max-width: 1200px;
  width: 96%;
  margin: 0 auto;
}

.centered-narrow {
  max-width: 1100px;
  width: 96%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Century Gothic", "Open Sans", "sans-serif";
}

h1,
h2 {
  font-weight: normal;
  text-transform: uppercase;
}

h3 {
  text-transform: uppercase;
  font-size: 16px;
}

a {
  color: black;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  background: none;
  border: 0;
  font-size: 18px;
  padding: 0;
  text-transform: capitalize;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

select {
  background-color: white !important;
}

.FeedbackButton {
  background-color: black;
  color: white;
  text-transform: uppercase;
  border: 1px solid black;
  border-radius: 0;
  margin-bottom: 0 !important;
  padding: 0.5rem !important;
}

.FeedbackButton:hover {
  background-color: white;
  color: black;
}

.ChartTitle {
  text-align: center;
}