.Content {
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SectionMain {
  width: 60%;
  padding-right: 2rem;
}

.SectionMain h3 {
  margin-top: 1.5rem;
}

.SectionOutput {
  width: 40%;
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
}

.SectionOutput > img {
  max-width: 100%;
}

.SectionTitle {
  width: 100%;
}

.Navigation {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.Navigation button {
  text-align: left;
  padding: 1rem 1rem 0.5rem;
}

.Navigation button.active,
.Navigation button:hover {
  background-color: white;  
  text-decoration: none;
}

.subNavigation {
  justify-content: center;
  margin: 2rem 0 3rem;
}

.subNavigation button {
  padding: 1rem;
}

.subNavigation button.active {
  font-weight: bold;
}

.Fields {
  width: 100%;
  padding-right: 4rem;
}

.Fields h2 {
  margin-top: 0;
}

.fieldset {
  display: none;
  flex-wrap: wrap;
  align-items: flex-start;
}

.fieldset.active {
  display: flex;
}

.input-group {
  margin-bottom: 1.5rem;
}

label {
  width: 100%;
  margin-bottom: 0;
  font-weight: 600;
  margin-left: 0.75rem;
}

.spacer {
  height: 40px;
}

.label {
  font-weight: bold;
  margin-left: 0.75rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.Outputs {
  margin-top: 2rem;
}

.DevResults {
  display: flex;
}

.DevResults > div {
  padding: 1rem;
  width: 50%;
}


.SubNavigationButton {
  cursor: pointer;
  display: inline-block;
}

.SubNavigationButton button {
  padding-left: 0.5rem;
}

.SubNavigationButton.active .Step,
.SubNavigationButton:hover .Step {
  background-color: black;
  color: white;
}

.Step {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid black;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  line-height: 28px;
}

.Glossary {
  width: 100%;
}

.Glossary h3 {
  margin-top: 2rem;
  font-weight: bold;
}

dl .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;
  margin: 0;
}

dl .row:hover dt,
dl .row:hover dd {
  background-color: #eee;
}

dt {
  width: 20%;
  padding-right: 2rem;
}

dd {
  width: 80%;
  margin: 0;
}

.HelpText {
  width: 100%;
  padding: 0.25rem 0.75rem 0.5rem;
  font-style: italic;
  font-size: 14px;
}